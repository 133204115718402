import React from "react";

const Input = ({ type, placeholder, onChange, id, ...props }) => {
  return (
    <input
      {...props}
      onChange={onChange}
      type={type ? type : "text"}
      placeholder={placeholder ? placeholder : ""}
      className="w-full h-11 flex items-center rounded bg-white outline-none ring-2 ring-gray-200 disabled:bg-gray-200 disabled:cursor-not-allowed focus:ring-blue-600 text-gray-800 px-4"
    />
  );
};

export default Input;
