import React from "react";
import Sidebar from "../Sidebar/Sidebar";

const Layout = ({ children }) => {
  return (
    <main>
      <div className="container">
        <div style={{ height: "calc(100vh - 75px)" }}>
          <div className="w-full py-12 flex lg:items-start justify-start lg:justify-between gap-8 flex-col lg:flex-row text-gray-800">
            <Sidebar />
            {/* <!-- Content */}
            <div className="w-full border shadow-lg px-5">{children}</div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
