import { onValue, ref } from "firebase/database";
import { db } from "../firebase";

const get_user_total = (uid) => {
  let total_created = 0;
  let today_created = 0;
  let monthly_created = 0;

  onValue(ref(db), (snapshot) => {
    const data = snapshot.val();
    const users = Object.keys(data || {});
    if (users.indexOf(uid) > -1) {
      const specific_user_data = data[uid];
      total_created = Object.keys(specific_user_data).length - 1;
      let today = 0;
      let monthly = 0;
      Object.entries(specific_user_data).map(([key, val]) => {
        if (
          val?.createdAt?.split(" ")[1] ===
          new Date().toDateString().split(" ")[1]
        ) {
          monthly++;
        }

        if (val?.createdAt === new Date().toDateString()) {
          today++;
        }
      });
      today_created = today;
      monthly_created = monthly;
    }
  });

  return { total_created, today_created, monthly_created };
};

export default get_user_total;
