import { ref, onValue } from "firebase/database";
import TostMessage from "../components/TostMessage";
import { db } from "../firebase";

export default function set_user_info(dispatch, user) {
  onValue(ref(db), (snapshot) => {
    const data = snapshot.val();
    if (data[user?.uid]?.user?.administrator) {
      let create_info = {
        email: user?.email,
        name: user?.displayName,
        phone: user?.phoneNumber,
        metadata: user?.metadata,
        isAnonymous: user?.isAnonymous,
        emailVerified: user?.emailVerified,
        uid: user.uid,
      };

      const obj = JSON.parse(user?.photoURL);
      create_info = { ...create_info, ...obj };

      dispatch({
        type: "set_user",
        payload: { ...create_info },
      });

      if (user?.email) {
        dispatch({ type: "set_authenticated" });
      }
    } else if (user?.uid) {
      TostMessage("Only administrator can access!", "error");
    }
  });
}
