import React from "react";

const Button = ({ children, onClick, type, loading, ...props }) => {
  return (
    <button
      onClick={onClick}
      {...props}
      type={type ? type : "button"}
      className="flex items-center justify-center px-4 gap-x-4 bg-blue-600 text-white focus:ring rounded w-full h-11 tracking-wider font-medium text-base"
    >
      {loading ? (
        <>
          <div className="w-5 h-5 mr-auto" />
          <span>{children}</span>
          <div className="ml-auto w-5 h-5 block rounded-full border-[3px] border-t-transparent animate-spin" />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
