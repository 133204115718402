import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Approved from "./pages/Approved";
import UnApproved from "./pages/UnApproved";
import ApprovedRequest from "./pages/ApprovedRequest";

import Header from "./components/Header/Header";
import Profile from "./pages/Profile";
import Security from "./pages/Security";

import Signin from "./pages/Signin";
import { useAuthContext } from "./context/AuthContext";
import PublicRoute from "./components/AuthRoutes/PublicRoute";
import PrivateRoute from "./components/AuthRoutes/PrivateRoute";
import { auth } from "./firebase";
import set_user_info from "./utils/set_user_info";
import LoadingScreen from "./components/Loading";
import ResetPassword from "./pages/ResetPassword";
import TostMessage from "./components/TostMessage";

const AllRoutes = () => {
  const { state, dispatch } = useAuthContext();

  const [user, set_user] = useState();
  const [loading_screen, set_ls] = useState("1");

  useEffect(() => {
    if (user?.email) {
      set_user_info(dispatch, user);
    }
  }, [user, dispatch]);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      set_user(user);
      setTimeout(() => {
        set_ls("2");
      }, 500);
    });
  }, [dispatch]);

  useEffect(() => {
    const action = localStorage.getItem("action");
    if (action === "login") {
      TostMessage("Successfully Logged In!", null, 900);
      localStorage.removeItem("action");
    }

    if (action === "register") {
      TostMessage("Successfully Registered!", null, 900);
      localStorage.removeItem("action");
    }
  }, [state]);

  if (loading_screen === "1") return <LoadingScreen />;

  return (
    <main>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Approved />
            </PrivateRoute>
          }
        />
        <Route
          path="/unapproved-users"
          element={
            <PrivateRoute>
              <UnApproved />
            </PrivateRoute>
          }
        />

        <Route
          path="/approve-request-users"
          element={
            <PrivateRoute>
              <ApprovedRequest />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path="/security"
          element={
            <PrivateRoute>
              <Security />
            </PrivateRoute>
          }
        />

        {/* Auth Route */}
        <Route
          path="/signin"
          element={
            <PublicRoute>
              <Signin />
            </PublicRoute>
          }
        />

        <Route
          path="/reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </main>
  );
};

export default AllRoutes;
