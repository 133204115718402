import React from "react";
import { Navigate } from "react-router-dom";

import { useAuthContext } from "../../context/AuthContext";

const PrivateRoute = ({ children }) => {
  const { state } = useAuthContext();
  if (!state?.authenticated) return <Navigate to="/signin" />;

  return children;
};

export default PrivateRoute;
