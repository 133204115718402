import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import Input from "../components/Common/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/Common/Button";
import { useAuthContext } from "../context/AuthContext";
import TostMessage from "../components/TostMessage";
import { updatePassword } from "firebase/auth";
import { auth } from "../firebase";

const Security = () => {
  const { dispatch, state } = useAuthContext();

  const [form, set_form] = useState({
    new_password: "",
    confirm_password: "",
  });

  useEffect(() => {
    set_form({});
  }, [state]);

  function update_handler(e) {
    e.preventDefault();
    dispatch({ type: "set_updating" });

    if (form.new_password !== form.confirm_password) {
      TostMessage("Password doesn't match!", "error");
      dispatch({ type: "reset_updating" });
      return;
    }

    updatePassword(auth.currentUser, form?.new_password)
      .then(() => {
        TostMessage("Password Updated!", "success");
      })
      .catch((error) => {
        TostMessage(error?.message, "error");
      })
      .finally(() => {
        dispatch({ type: "reset_updating" });
      });
  }

  return (
    <Layout>
      <div className="w-full bg-white rounded-sm shadow-sm">
        <div className="px-5 py-4 text-blue-600 border-b flex justify-between items-center">
          <span className="text-2xl font-medium tracking-wide">
            Change Password
          </span>
        </div>

        <form
          onSubmit={update_handler}
          className="grid grid-cols-12 gap-y-6 p-5"
        >
          <div className="col-span-12">
            <label className="mb-2 block" htmlFor="new_password">
              New Password
            </label>
            <Input
              required={true}
              onChange={(e) =>
                set_form((p) => ({ ...p, new_password: e.target.value }))
              }
              id="new_password"
              name="new_password"
              placeholder="New Password"
              type="password"
            />
          </div>
          <div className="col-span-12">
            <label className="mb-2 block" htmlFor="confirm_password">
              Confirm Password
            </label>
            <Input
              required={true}
              onChange={(e) =>
                set_form((p) => ({ ...p, confirm_password: e.target.value }))
              }
              type="password"
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm Password"
            />
          </div>

          <div className="col-span-12">
            <div className="w-fit">
              <Button type="submit" loading={state?.updating}>
                {state?.updating ? (
                  "Updating"
                ) : (
                  <>
                    <FontAwesomeIcon icon={faLock} />
                    <span>Update Password</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Security;
