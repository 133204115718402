import * as React from "react";
import ReactDOM from "react-dom/client";
import "./input.css";
import "./custom.css";
import Routes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./context";
import "react-toastify/dist/ReactToastify.min.css";

const root = ReactDOM.createRoot(document.getElementById("rjs-app"));

root.render(
  <ContextProvider>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </ContextProvider>
);