import { createContext, useReducer, useContext } from "react";

const State = {
  approved_users: [],
  unapproved_users: [],
  request_users: [],
  fetched: false,
};

const reducer = (state = State, action) => {
  const { type, payload } = action;
  switch (type) {
    case "set_approved_users":
      return { ...state, approved_users: payload };
    case "set_unapproved_users":
      return { ...state, unapproved_users: payload };
    case "set_request_users":
      return { ...state, request_users: payload };

    case "set_fetched":
      return { ...state, fetched: payload };

    case "reset_users":
      return { ...state };

    default:
      return state;
  }
};

const USERSContext = createContext();

const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, State);

  return (
    <USERSContext.Provider value={{ state, dispatch }}>
      {children}
    </USERSContext.Provider>
  );
};

const useUsersContext = () => useContext(USERSContext);

export { UsersProvider, useUsersContext };
