import React from "react";
import Loading from "../assets/images/loading.gif";

export function Loading2() {
  return (
    <div className="w-full h-screen fixed inset-0 m-auto z-50 flex items-center justify-center flex-col gap-y-5">
      <div className="fixed w-[800px] h-[400px] m-auto inset-0 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 z-30 animate-pulse rounded-xl" />

      <h3 className="text-lg font-medium tracking-wide text-black relative z-50">
        Please wait...
      </h3>
      <img className="w-20 h-20 z-50" src={Loading} alt="" />   </div>
  );
}

const LoadingScreen = () => {
  return (
    <div className="w-full h-screen fixed inset-0 z-50 flex items-center justify-center flex-col gap-y-5">
      <div className="fixed w-full h-screen inset-0 bg-gradient-to-r from-gray-200 via-gray-400 to-gray-500 z-30 animate-pulse" />

      <h3 className="text-lg font-medium tracking-wide text-black relative z-50">
        Please wait...
      </h3>
      <div className="w-10 h-10 rounded-full border-4 border-black animate-spin border-t-transparent relative z-50" />
    </div>
  );
};

export default LoadingScreen;
