import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import Input from "../components/Common/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/Common/Button";
import { useAuthContext } from "../context/AuthContext";

import { updateProfile, updateEmail } from "firebase/auth";
import { auth } from "../firebase";
import set_user_info from "../utils/set_user_info";
import TostMessage from "../components/TostMessage";

import firebase_upload_image from "../utils/firebase_upload_image";

const Home = () => {
  const { state, dispatch } = useAuthContext();
  const [edit, set_edit] = useState(false);
  const [form, set_form] = useState({});
  const [uploading, set_uploading] = useState(false);

  useEffect(() => {
    set_form({
      name: state?.user?.name || "",
      email: state?.user?.email || "",
      phone: state?.user?.phone || "",
      photo: state?.user?.photo || "",
    });
  }, [state]);

  async function change_handler(e) {
    const id = e.target.id;

    if (id === "photo") {
      const file = e.target.files[0];

      if (!file) return;
      firebase_upload_image(file, set_uploading, (url) => {
        set_form((p) => ({
          ...p,
          photo: url,
        }));
      });
    } else {
      set_form((p) => ({ ...p, [id]: e.target.value }));
    }
  }

  async function update_handler(e) {
    e.preventDefault();
    dispatch({ type: "set_updating" });
    const object_for_photoURL = {
      photo: form?.photo,
      phone: form?.phone,
    };

    updateProfile(auth.currentUser, {
      displayName: form?.name,
      photoURL: JSON.stringify(object_for_photoURL),
    }).then(() => {
      updateEmail(auth.currentUser, state?.email).then(() => {
        auth.onAuthStateChanged((user) => {
          TostMessage("Successfully updated!");
          set_user_info(dispatch, user);
          dispatch({ type: "reset_updating" });
          set_edit(false);
        });
      });
    });
  }

  return (
    <Layout>
      <div className="w-full bg-white rounded-sm shadow-sm">
        <div className="px-5 py-4 text-blue-600 border-b flex justify-between items-center">
          <span className="text-2xl font-medium tracking-wide">Profile</span>

          {!edit && (
            <button
              type="button"
              onClick={() => set_edit(true)}
              className="flex items-center gap-x-2"
            >
              <span>Edit Profile</span>
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
        </div>

        <form
          onSubmit={update_handler}
          className="grid grid-cols-12 gap-y-6 p-5"
        >
          <div className="col-span-12">
            <label className="mb-2 block" htmlFor="name">
              Name
            </label>
            <Input
              required={true}
              placeholder={form?.name}
              onChange={change_handler}
              disabled={!edit}
              id="name"
            />
          </div>
          <div className="col-span-12">
            <label className="mb-2 block" htmlFor="email">
              Email
            </label>
            <Input
              required={true}
              placeholder={form?.email}
              onChange={change_handler}
              disabled={!edit}
              type="email"
              id="email"
            />
          </div>

          <div className="col-span-12">
            <label className="mb-2 block" htmlFor="phone">
              Phone
            </label>
            <Input
              placeholder={form?.phone}
              onChange={change_handler}
              disabled={!edit}
              id="phone"
            />
          </div>

          <div className="col-span-12">
            <label className="mb-2 block" htmlFor="photo">
              Change Avatar
            </label>
            <input
              onChange={change_handler}
              disabled={!edit}
              className="w-full flex items-center rounded bg-white outline-none ring-2 ring-gray-200 focus:ring-blue-600 text-gray-800 px-4 py-2 disabled:bg-gray-200 disabled:cursor-not-allowed"
              id="photo"
              type="file"
            />
            <div className="w-40 mt-3 rounded overflow-hidden">
              {uploading ? (
                <div className="w-full h-32 bg-black animate-pulse flex items-center justify-center">
                  <span className="text-white font-bold">Uploading</span>
                </div>
              ) : (
                <img
                  className="w-full object-contain"
                  src={form?.photo}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="col-span-12">
            <div className="w-fit">
              {edit && (
                <Button type="submit" loading={state?.updating}>
                  {state?.updating ? (
                    "Updating"
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faFloppyDisk} />
                      <span>Save Changes</span>
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Home;
