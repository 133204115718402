import React, { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { db } from "../firebase";
import { ref, update, onValue, set } from "firebase/database";
import get_user_total from "../utils/get_user_total";

const Approved = () => {
  const [approved_users, set_approved_users] = useState([]);

  useEffect(() => {
    return onValue(ref(db), (snapshot) => {
      const data = snapshot.val();
      const users = Object.values(data || {});
      set_approved_users([]);
      users.map((user) => {
        if (user?.user?.approved) {
          if (user) {
            if (user["user"]["approved"] === "1") {
              set_approved_users((p) => [...p, user]);
            }
          }
        }
      });
    });
  }, []);

  function delete_history_handle(obj) {
    const user = Object.entries(obj)?.filter(
      ([key, val]) => key === "user"
    )[0][1];

    set(ref(db, `/${user?.uid}`), {
      user,
    });
  }

  function unapproved_handler(user) {
    update(ref(db, `/${user?.uid}`), {
      user: {
        ...user,
        approved: "0",
      },
    })
      .then(() => console.log("approved"))
      .catch((error) => console.log("approved error: ", error?.message))
      .finally(() => {
        console.log("finished");
      });
  }

  return (
    <Layout>
      <div className="w-full bg-white rounded-sm shadow-sm">
        <div className="px-5 py-4 text-blue-600 text-2xl font-medium tracking-wide border-b">
          Approved Users
        </div>

        <div className="py-6 space-y-6">
          {approved_users?.map((d, i) =>
            d?.user?.email === "nidcreator@gmail.com" ? (
              <React.Fragment key={i}></React.Fragment>
            ) : (
              <div
                className="flex items-center justify-between bg-green-200 p-4 shadow border rounded"
                key={i}
              >
                <div>
                  <p>
                    <small>User Email:</small> <b>{d?.user?.email}</b>
                  </p>
                  <p className="flex items-center justify-start gap-x-2">
                    <small>Today Created:</small>
                    <b>{get_user_total(d?.user?.uid).today_created}</b>
                  </p>
                  <p className="flex items-center justify-start gap-x-2">
                    <small>This Month:</small>
                    <b>{get_user_total(d?.user?.uid).monthly_created}</b>
                  </p>
                  <p className="flex items-center justify-start gap-x-2">
                    <small>Total Created:</small>
                    <b>{get_user_total(d?.user?.uid).total_created}</b>
                  </p>
                </div>

                <div className="flex flex-col justify-start items-start gap-2">
                  <button
                    onClick={() => delete_history_handle(d)}
                    className="text-sm bg-red-400 text-white px-2 py-1 rounded focus:ring"
                  >
                    {" "}
                    Delete History{" "}
                  </button>
                  <button
                    onClick={() => unapproved_handler(d?.user)}
                    className="text-sm px-4 py-2 rounded shadow focus:ring-1 focus:ring-offset-1 focus:ring-red-500 bg-red-500 text-white flex items-center gap-x-1"
                  >
                    <span> Remove from approved </span>
                    <span>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </span>
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Approved;
