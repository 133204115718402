import React from "react";
import { AuthProvider } from "./AuthContext";
import { UsersProvider } from "./UsersContext";
const ContextProvider = ({ children }) => {
  return (
    <AuthProvider>
      <UsersProvider>{children}</UsersProvider>
    </AuthProvider>
  );
};

export default ContextProvider;
