import React, { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { db } from "../firebase";
import { ref, onValue, update } from "firebase/database";

const ApprovedRequest = () => {
  const [request_users, set_request_users] = useState([]);

  useEffect(() => {
    return onValue(ref(db), (snapshot) => {
      const data = snapshot.val();
      const users = Object.values(data || {});
      set_request_users([]);
      users.map((user) => {
        if (user?.user?.approved) {
          if (user["user"]["approved"] === "request") {
            set_request_users((p) => [...p, user]);
          }
        }
      });
    });
  }, []);

  function approved_handler(user) {
    update(ref(db, `/${user?.uid}`), {
      user: {
        ...user,
        approved: "1",
      },
    })
      .then(() => console.log("approved"))
      .catch((error) => console.log("approved error: ", error?.message))
      .finally(() => {
        console.log("finished");
      });
  }

  function reject_handler(user) {
    update(ref(db, `/${user?.uid}`), {
      user: {
        ...user,
        approved: "0",
      },
    })
      .then(() => console.log("rejected"))
      .catch((error) => console.log("reject error: ", error?.message))
      .finally(() => {
        console.log("finished");
      });
  }

  return (
    <Layout>
      <div className="w-full bg-white rounded-sm shadow-sm">
        <div className="px-5 py-4 text-blue-600 text-2xl font-medium tracking-wide border-b">
          Request Approved Users
        </div>

        <div className="py-6 space-y-6">
          {request_users?.map((d, i) => (
            <div
              className="flex items-center justify-between bg-blue-100 p-4 shadow border rounded"
              key={i}
            >
              <div>
                <small>User Email:</small> <b>{d?.user?.email}</b>
              </div>
              <div className="space-x-3 flex items-center">
                <button
                  onClick={() => approved_handler(d?.user)}
                  className="text-sm px-4 py-2 rounded shadow focus:ring-1 focus:ring-offset-1 focus:ring-green-600 bg-green-600 text-white flex items-center gap-x-1"
                >
                  <span> Accept Request </span>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </button>
                <button
                  onClick={() => reject_handler(d?.user)}
                  className="text-sm px-4 py-2 rounded shadow focus:ring-1 focus:ring-offset-1 focus:ring-red-500 bg-red-500 text-white flex items-center gap-x-1"
                >
                  <span> Reject Request </span>
                  <span>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ApprovedRequest;
